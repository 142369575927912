import { create } from "zustand";
import { getGenSystemAllList } from "../services2";
import { System, SystemConfig } from "../services2/typings";

interface SystemState {
    
    initialized: boolean;
    allSystem: System[];
    currentSystem: System | null;

    initAllSystem: () => Promise<void>;
    initCurrentSystem: () => Promise<void>;

    getCurrentSystem: () => Promise<System | null>;
}

const localSystemConfigMao: Record<string, SystemConfig> = {
    "posture": {
        version: "v1",
        domainHost: "training.diyifanghu.com",
        passwordEncryptKey: "thanks,posturewz",
        oauthBasicKey: "posture:posturea",
    },
    "dyfh": {
        version: "v1",
        domainHost: "dyfh.diyifanghu.com",
        passwordEncryptKey: "thanks,dyfhwxapp",
        oauthBasicKey: "dyfh:password",
    }
}

//开发环境下，根据环境变量配置
if(process.env.NODE_ENV === 'development') {
    if(process.env.REACT_APP_DEV_GENSYS) {
        localSystemConfigMao[process.env.REACT_APP_DEV_GENSYS].domainHost = "localhost";
    }
}

export const useSystemStore = create<SystemState>((set, get) => ({

    allSystem: [],
    currentSystem: null,
    initialized: false,

    initAllSystem: async () => {
        const allSystem = await getGenSystemAllList();
        console.log("allSystem", allSystem);
        for(const sys of allSystem) {
            let shouldResetSysConfig = false;
            try {
                if(!sys.sysConfig) {
                    shouldResetSysConfig = true;
                }
                sys.parsedSysConfig = JSON.parse(sys.sysConfig);
                if(typeof sys.parsedSysConfig !== 'object') { 
                    sys.parsedSysConfig = {};
                    shouldResetSysConfig = true;
                }else if(Array.isArray(sys.parsedSysConfig)) {
                    sys.parsedSysConfig = {};
                    shouldResetSysConfig = true;
                }
            } catch (error) {
                sys.parsedSysConfig = {};
                shouldResetSysConfig = true;
            }
            if(shouldResetSysConfig) {
                sys.isUnrecognized = true;
                sys.unrecognizedReason = "系统配置不是对象";
            }else if(!sys.parsedSysConfig.version) {
                sys.isUnrecognized = true;
                sys.unrecognizedReason = "未定义系统配置版本号";
            }else if(sys.parsedSysConfig.version !== "v1") {
                sys.isUnrecognized = true;
                sys.unrecognizedReason = "系统配置版本号不支持";
            }
            sys.parsedSysConfig = {
                ...sys.parsedSysConfig,
                ...localSystemConfigMao[sys.sysKey],
            }
        }
        set({ allSystem, initialized: true });
    },
    
    initCurrentSystem: async () => {
        //根据域名判断当前系统
        const hostname = window.location.hostname;
        const allSystem = get().allSystem;
        const currentSystem = allSystem.find((s) => s.parsedSysConfig?.domainHost === hostname);
        if(!currentSystem) {
            return ;
        }
        console.log("currentSystem", currentSystem);
        set({ currentSystem });
    },

    getCurrentSystem: async () => {
        let state = get();
        await state.initAllSystem();
        await state.initCurrentSystem();
        state = get();
        if(!state.currentSystem) {
            return null;
        }else {
            return state.currentSystem;
        }
    }

    
}));