import { Spin } from "antd";

export default function App_Loading(props: {
    tip?: string;
}) {
    const tip = props.tip || "页面加载中...";   
    return (

        <Spin size="large" spinning tip={tip}>
            <div className="page-app page-full" />
        </Spin>
    )
}