
export const apis = {
  ipsalogin: {
      api: '/isee/api/sys/user/ipsalogin',
      common: '系统登录',
  },
  login: {
      api: '/isee/api/sys/user/login',
      common: '云应平台登录',
  },
  logout: {
      api: '/isee/api/sys/user/logout',
      common: '云应平台退出',
  },
  excel: {
      api: '/isee/api/common/getExcelByBusinessCode',
      common: '下载excel',
  },
  dataTree: {
      api: '/isee/api/common/getDataByTree',
      common: '获取菜单',
  },
  data: {
      api: '/isee/api/common/getData',
      common: '获取数据',
  },
  dataPage: {
      api: '/isee/api/common/getDataByPage',
      common: '获取表格分页数据',
  },
  impleData: {
      api: '/isee/api/wx/pubnum/user/manualMeau',
      common: '执行数据',
  },
  mediaData: {
      api: '/isee/api/wx/media/info/getInfo',
      common: '获取回复素材数据',
  },
  getMenu: {
      api: '/isee/api/wx/menu/info/getMenu',
      common: '获取公众号菜单创建数据'
  },
  getMenux: {
      api: '/isee/api/wx/common/getMenu',
      common: '获取公众号菜单创建数据'
  },
  saveMenu: {
      api: '/isee/api/wx/menu/info/saveMenu',
      common: '修改公众号菜单创建数据'
  },
  publishMenu: {
      api: '/isee/api/wx/menu/info/publish',
      common: '保存公众号菜单创建数据'
  },
  publishMenux: {
      api: '/isee/api/wx/common/createMenu',
      common: '保存公众号菜单创建数据'
  },
  getUrl: {
      api: '/isee/api/wx/common/getPreAuthCode',
      common: '获取二维码路径',
  },
  uploadPic: {
      api: '/comm-top/common/sys/upload',
      common: '上传按钮',
  },
  getTableData:{
      api: '/isee/api/visua/sql/getByPage',
      common: '获取接口数据',
  },
  getSqlData:{
      api: '/isee/api/visua/sql/testMode',
      common: '获取sql数据',
  },
  changeBaseCode:{
      api: '/isee/api/visua/sql/getByBusinessCodeCount',
      common: '修改接口数据',
  },
  submitBaseCode:{
      api: '/isee/api/visua/sql/save',
      common: '添加接口数据',
  },
};
