import { lazy, Suspense } from "react";
import App_Loading from "./app-loading";
import { System } from "./services2/typings";

const AdminRouter = lazy(() => import('./routers/admin-router'));
const GenSysRouter = lazy(() => import('./routers/gen-sys-router'));

export const App_Router = (props: {
    appType: "ipage" | "pig-ipage" | "system";
    system: System | null;
}) => {

    console.log("App_Router", props);

    return ['ipage', 'pig-ipage'].includes(props.appType) ? (
        <Suspense fallback={(<App_Loading />)}>
            <AdminRouter appType={props.appType as ("ipage" | "pig-ipage")} />
        </Suspense>
    ) : ['system'].includes(props.appType) && props.system ? (
        <Suspense fallback={(<App_Loading tip="系统加载中..." />)}>
            <GenSysRouter appType={props.appType as "system"} sysKey={props.system.sysKey} />
        </Suspense>
    ) : <></>
}

