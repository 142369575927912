import qs from "qs";
import { apis } from "./apis";
import { ApiKey, RequestConfig, SimpleRequestConfig } from "./typings";

/**
 * 基本原则，以config值优先
 */
const host = window.location.protocol + "//" +  window.location.host + (process.env.NODE_ENV === 'development' ? `/dev` : "");

let tokenCache = "";
let defaultHandlerCache: RequestConfig['requestHandler'] = {
    resErrorHandle: (res: any) => {
        console.error(res);
    },
    resExceptionHandle: (e: any) => {
        console.error(e);
    }
}

export const registerRequestHandler = (config: Partial<typeof defaultHandlerCache>) => {
    defaultHandlerCache = {
        ...defaultHandlerCache,
        ...config,
    }
}

const getToken = (headers: any) => {
    if (headers?.token) {
        return headers.token;
    } else {
        if (!tokenCache) {
            tokenCache = localStorage.getItem("token") || "";
        }
        return tokenCache;
    }
}


export const request = (api: ApiKey | string, config: RequestConfig) => {
    if (!config.method) {
        throw new Error("method is required");
    }
    //处理url
    let url = api;
    if (!url) {
        throw new Error(`api ${api} not found`);
    }
    if (url.indexOf("http") === -1) {
        url = apis[url as ApiKey].api;
    }
    url = host + url;
    const configIns = {
        method: config.method,
        headers: config.headers || {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: config.data,
        query: config.query ? "?" + qs.stringify(config.query) : "",
        tokenRequired: typeof config.tokenRequired === 'boolean' ? config.tokenRequired : true,
        requestHandler: {
            ...defaultHandlerCache,
            ...config.requestHandler,
        },
    }
    //处理headers Content-Type
    if (!configIns.headers['Content-Type']) {
        configIns.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }
    //处理headers token
    if (configIns.tokenRequired) {
        const token = getToken(config.headers);
        if (token) {
            configIns.headers['Token'] = token;
        }
    }
    //处理data，除了GET方法，其他方法都需要处理data
    if (configIns.method !== 'GET') {
        if (configIns.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
            let data: any = {};
            for (const key in configIns.data) {
                if (typeof configIns.data[key] === 'object') {
                    data[key] = JSON.stringify(configIns.data[key]);
                }else {
                    data[key] = configIns.data[key];
                }
            }
            configIns.data = qs.stringify(data);
        } else if (configIns.headers['Content-Type'] === 'application/json') {
            configIns.data = JSON.stringify(configIns.data);
        }
    }

    const handler = fetch(url + configIns.query, {
        method: configIns.method,
        headers: configIns.headers,
        body: configIns.data,
    }).then((res) => {
        return res.json();
    }).then((res) => {
        //如果有codeSymbol和codeSuccess&codeFail，那么就按照这个来处理 codeSymbol可能含有多级，比如data.code
        const codeSymbol = configIns.requestHandler.resCodeSymbol;
        const codeSuccess = configIns.requestHandler.resCodeSuccess;
        const codeFail = configIns.requestHandler.resCodeFail;
        const dataSymbol = configIns.requestHandler.resDataSymbol;
        const messageSymbol = configIns.requestHandler.resMessageSymbol;

        let code = res;
        if (codeSymbol) {
            const codeSymbolArr = codeSymbol.split(".");
            for (let i = 0; i < codeSymbolArr.length; i++) {
                code = code[codeSymbolArr[i]];
            }
        }
        let data = res;
        if (typeof codeSuccess !== 'undefined' && code === codeSuccess) {
            if (code === codeSuccess) {
                if (dataSymbol) {
                    const dataSymbolArr = dataSymbol.split(".");
                    for (let i = 0; i < dataSymbolArr.length; i++) {
                        data = data[dataSymbolArr[i]];
                    }
                }
            }
        }
        let message = res;
        if (messageSymbol) {
            const messageSymbolArr = messageSymbol.split(".");
            for (let i = 0; i < messageSymbolArr.length; i++) {
                message = message[messageSymbolArr[i]];
            }
        }

        if (typeof codeFail !== 'undefined' && code === codeFail) {
            configIns.requestHandler.resErrorHandle &&
                configIns.requestHandler.resErrorHandle(code, message, data, res);
            return null;
        }
        
        return data;
    }).catch((e) => {
        configIns.requestHandler.resExceptionHandle && configIns.requestHandler.resExceptionHandle(e);
    });

    return handler;

}

/**
 * post请求
 * @param apiKey 
 * @param data 
 * @param query 
 * @param config 
 * @returns 
 */
export const $post = (apiKey: ApiKey, data: any, query?: Record<string, any>, config?: SimpleRequestConfig) => {
    const _config = config || {};
    return request(apiKey, {
        method: "POST",
        data,
        query,
        ..._config,
    });
}

export const $get = (apiKey: ApiKey, query?: any, config?: SimpleRequestConfig) => {
    const _config = config || {};
    return request(apiKey, {
        method: "GET",
        query,
        ..._config,
    });
}

export const $put = (apiKey: ApiKey, data: any, query?: Record<string, any>, config?: SimpleRequestConfig) => {
    const _config = config || {};
    return request(apiKey, {
        method: "PUT",
        data,
        query,
        ..._config,
    });
}

export const $delete = (apiKey: ApiKey, data: any, query?: Record<string, any>, config?: SimpleRequestConfig) => {
    const _config = config || {};
    return request(apiKey, {
        method: "DELETE",
        query,
        ..._config,
    })
}

