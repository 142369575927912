import { message } from "antd";
import { Session } from "../pages/login2/cookie";
import { BusinessTemplate, Page, SysDirItem, SysItem, SysParam, System } from "./typings";

//获取cookie
// export const getCookie = (name: string) => {
//     let arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
//     if (arr = document.cookie.match(reg)) {
//         return unescape(arr[2]);
//     } else {
//         return null;
//     }
// }
// const pigAuthorization = () => `Bearer ${Session.get('token')}`
// const prefix = process.env.NODE_ENV === 'development' ? '/pig' : 'https://pig.diyifanghu.com/api'  

export const pigAuthorization = () => `Bearer ${Session.get('token')}`

//兼容pig.diyifanghu.com和ipage.diyifanghu.com的部署
const prefix = process.env.NODE_ENV === 'development' ? '/pig' : (window.location.hostname === 'ipage.diyifanghu.com' ? '/pigapi' : '/api');

export const host = window.location.protocol + '//' +  window.location.host + prefix;


/**
 * 获取服务器支持的conf
 * @deprecated
 */
export const getGenConfV1Json = async () => {
    return fetch(`${prefix}/gen/conf/v1/json`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': pigAuthorization()
        }
    }).then((res) => {
        return res.json()
    }).then((res) => {
        if(res.code === 0) {
            return JSON.parse(res.data);
        }else {
            message.warning(res.msg);
        }
    })

}
// /gen/comm/v1/conf
export const getV1Conf = async () => {
    return fetch(`${prefix}/gen/comm/v1/conf`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': pigAuthorization()
        }
    }).then((res) => {
        return res.json()
    }).then((res) => {
        if(res.code === 0) {
            return res.data;
        }else {
            message.warning(res.msg);
        }
    })
}
export const getV1NewConf = async () => {
    return fetch(`${prefix}/gen/comm/v1/newConf`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': pigAuthorization()
        }
    }).then((res) => {
        return res.json()
    }).then((res) => {
        if(res.code === 0) {
            return res.data;
        }else {
            message.warning(res.msg);
        }
    })
}


/**
 * 所属系统查询
 * @returns 
 */
export const getAdminSystemList = async (): Promise<SysItem[]> => {
    return fetch(`${prefix}/admin/sysSystem/list`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': pigAuthorization()
        }
    }).then((res) => {
        return res.json()
    }).then((res) => {
        if(res.code === 0) {
            return res.data;
        }else {
            message.warning(res.msg);
        }
    })
}


export const getAdminGenSystemList = async (): Promise<Page<SysItem>> => {
    return fetch(`${prefix}/gen/genSystem/page`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': pigAuthorization()
        }
    }).then((res) => {
        return res.json()
    }).then((res) => {
        if(res.code === 0) {
            return res.data;
        }else {
            message.warning(res.msg);
        }
    })
}



/**
 * 获取dir tree
 */
export const getGenDirTree = async (sysKey: string): Promise<SysDirItem[]> => {
    return fetch(`${prefix}/gen/genDir/tree?datasourceKey=sys_gen&linkTab=gen_business_template&sysKey=${sysKey}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': pigAuthorization()
        }
    }).then((res) => {
        return res.json()
    }).then((res) => {
        if(res.code === 0) {
            return res.data;
        }else {
            message.warning(res.msg);
            return [];
        }
    })
}

/**
 * 数据源查询
 */
export const getGenDsconfList = async () => {
    return fetch(`${prefix}/gen/dsconf/list`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': pigAuthorization()
        }
    }).then((res) => {
        return res.json()
    }).then((res) => {
        if(res.code === 0) {
            return res.data
        }else {
            message.warning(res.msg);
        }
    })
}

/**
 * 新增业务模板
 */
export const postGenBusinessTemplate = async (data: {
    businessCode: string;
    businessDesc: string;
    sysKey: string;
}) => {
    return fetch(`${prefix}/gen/genBusinessTemplate`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': pigAuthorization()
        },
        body: JSON.stringify(data)
    }).then((res) => {
        return res.json()
    }).then((res) => {
        if(res.code === 0) {
            return res.data;
        }else {
            message.warning(res.msg);
        }
    })

}

/**
 * 分页查询业务模板
 */
export const getGenBusinessTemplatePage = async (sysKey: string, page: number, pageSize: number): Promise<Page<BusinessTemplate>> => {
    return fetch(`${prefix}/gen/genBusinessTemplate/page?sysKey=${sysKey}&current=${page}&pageSize=${pageSize}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': pigAuthorization()
        }
    }).then((res) => {
        return res.json()
    }).then((res) => {
        if(res.code === 0) {
            return res.data;
        }else {
            message.warning(res.msg);
            return null;
        }
    })

}


/**
 * 修改业务模板
 */
export const putGenBusinessTemplate = async (data: {
    id: string;
    businessCode: string;
    businessDesc: string;
    sysKey: string | null;
    sysDirId: string | null;
    inputData: string;
    outputData: string;
    paramAnalysis: string;
    templateVal: string;
    templateXml: string;
}) => {
    return fetch(`${prefix}/gen/genBusinessTemplate`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            'Authorization': pigAuthorization()
        },
        body: JSON.stringify(data)
    }).then((res) => {
        return res.json()
    })

}


/**
 * 根据dictType获取字典
 */
export const getDictsByType = async (dictType: string) => {
    return fetch(`${prefix}/admin/dict/item/list/?dictType=${dictType}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': pigAuthorization()
        }
    }).then((res) => {
        return res.json()
    }).then((res) => {
        if(res.code === 0) {
            return res.data
        }else {
            message.warning(res.msg);
        }
    })

}

export const deleteGenBusinessTemplate = async (id: string) => {
    return fetch(`${prefix}/gen/genBusinessTemplate`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            'Authorization': pigAuthorization()
        },
        body: JSON.stringify([id])
    }).then((res) => {
        return res.json()
    })
}

/**
 * 测试运行
 */
export const getData = async (businessCode: string, params: any) => {
    return fetch(`${prefix}/gen/comm/v1/getData`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': pigAuthorization()
        },
        body: JSON.stringify({
            businessCode,
            params: params || {}
        })
    }).then((res) => {
        return res.json()
    });
}


export const getGenSystemAllList = async (): Promise<System[]> => {
    return fetch(`${prefix}/gen/genSystem/allList`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        }
    }).then((res) => {
        return res.json()
    }).then((res) => {
        if(res.code === 0) {
            return res.data
        }else {
            message.warning(res.msg);
            return [];
        }
    }).catch(e => {
        message.error(e);
        return [];
    }) 
}

export const putGenSystem = async (id: number, data: Partial<System>) => {
    return fetch(`${prefix}/gen/genSystem`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            'Authorization': pigAuthorization()
        },
        body: JSON.stringify({
            id,
            ...data
        })
    }).then((res) => {
        return res.json()
    }).catch((e) => {
        message.error(e);
    })
}

export const getGenParamPage = async (sysKey: string, page: number=1, pageSize: number=20, keyword = ""): Promise<Page<SysParam> | null> => {
    return fetch(`${prefix}/gen/genParam/page?sysKey=${sysKey}&current=${page}&pageSize=${pageSize}${keyword ? `&paramName=${keyword}&paramDesc=${keyword}` : ''}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': pigAuthorization()
        }
    }).then((res) => {
        return res.json()
    }).then((res) => {
        if(res.code === 0) {
            return res.data;
        }else {
            message.warning(res.msg);
            return null;
        }
    })
}