import { create } from "zustand";
import { System } from "../services2/typings";
import { useSystemStore } from "./system-store";

interface AppState {

    loading: boolean;
    appType: "ipage" | "pig-ipage" | "system" | null;
    system: System | null;
    load: () => Promise<void>;

}

export const useAppStore = create<AppState>((set, get) => ({

    loading: true,
    appType: null,
    system: null,
    load: async () => {
        //开发环境下，根据环境变量配置

        let flag = false;
        if(process.env.NODE_ENV === 'development') {
            if(process.env.REACT_APP_DEV_IPAGE) {
                set({ appType: process.env.REACT_APP_DEV_IPAGE as 'ipage'});
                flag = true;
            }
        }
        if(!flag) {
            if(window.location.hostname === 'ipage.diyifanghu.com' ) {
                set({ appType: "ipage" });
            }else if(window.location.hostname === 'pig.diyifanghu.com') {
                set({ appType: "pig-ipage" });
            }else {
                const system = await useSystemStore.getState().getCurrentSystem();
                if(system) {
                    set({ appType: "system", system });
                }
            }

        }
        set({ loading: false });
    }

}));
