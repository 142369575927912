import { ConfigProvider } from "antd";
import zh_CN from 'antd/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import { lazy, Suspense, useEffect } from "react";
import App_Loading from "./app-loading";
import { App_Router } from "./app-router";
import './app.css';
import './config';
import { useAppStore } from "./store/app-store";

dayjs.locale('zh-cn');

const App_UnSupport = lazy(() => import("./app-un-support"));


export const App = () => {

    const loading = useAppStore(store => store.loading);
    const load = useAppStore(store => store.load);
    const appType = useAppStore(store => store.appType);
    const system = useAppStore(store => store.system);

    useEffect(() => {
        load();
    }, []);

    return (
        <ConfigProvider locale={zh_CN}>
            {
                loading ? (
                    <App_Loading tip="初始化数据加载中..." />
                ) : (
                    appType ? (
                        <App_Router
                            appType={appType}
                            system={system}
                        />
                    ) : (
                        <Suspense fallback={<App_Loading />}>
                            <App_UnSupport />
                        </Suspense>
                    )
                )
            }
        </ConfigProvider>
    )

}