import { registerRequestHandler } from "./services/request";
import { message, notification } from 'antd';

registerRequestHandler({
    resDataSymbol: "data",
    resCodeSymbol: "code",
    resMessageSymbol: "message",
    resCodeFail: -1,
    resCodeSuccess: 0,
    resErrorHandle: (code, msg, data, res) => {
        console.error("error", code, message, data, res);
        message.warning(msg);
    },
    resExceptionHandle: (e) => {
        console.error("exception", e);
        notification.warning({
            message: '数据错误：',
            description: e.message,
          });
    }
});
